import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';

import Header from './Header/Header';
import RightColumn from './RightColumn/RightColumn';
import BottomNavigationBar from './BottomNavigationBar/BottomNavigationBar';
import { breakpoint12, breakpoint7, breakpoint9 } from '../../utils/layouts';
import LeftColumn from './LeftColumn/LeftColumn';

import './PageLayout.scss';

const PageLayout = (props: React.PropsWithChildren<{}>): JSX.Element => {
    const tabletPlus = useMediaQuery(breakpoint7);
    const tabletLandscapePlus = useMediaQuery(breakpoint9);
    const desktop = useMediaQuery(breakpoint12);
    const [scrollTrigger, setScrollTrigger] = useState<HTMLElement | null>(
        null,
    );

    return (
        <>
            <Header scrollTrigger={scrollTrigger} />
            <Grid id='columns-container' container>
                {tabletLandscapePlus && <LeftColumn />}
                <Grid
                    id='center-column'
                    item
                    xs
                    ref={(node: HTMLDivElement) => setScrollTrigger(node)}
                >
                    {props.children}
                </Grid>
                {desktop && <RightColumn />}
            </Grid>
            {!tabletPlus && <BottomNavigationBar />}
        </>
    );
};

export default PageLayout;
