import { Box, Paper } from '@mui/material';

interface CardContainerProps {
    id?: string | undefined;
    title?: string | undefined;
    className?: string | undefined;
}

const CardContainer = (
    props: React.PropsWithChildren<CardContainerProps>,
): JSX.Element => {
    return (
        <Paper
            id={props.id}
            className={`card-container ${props.className}`}
            elevation={0}
        >
            {props.title && (
                <Paper className='card-header' elevation={0}>
                    {props.title}
                </Paper>
            )}
            <Box className='card-content'>{props.children}</Box>
        </Paper>
    );
};

export default CardContainer;
