import { Paper } from '@mui/material';

import Navigation from '../Navigation/Navigation';

import './BottomNavigationBar.scss';

const BottomNavigationBar = (): JSX.Element => {
    return (
        <Paper id='navigation-bar' elevation={0}>
            <Navigation />
        </Paper>
    );
};

export default BottomNavigationBar;
