import { createContext } from 'react';
import { UserModel } from '../models/User';

import { VotesInContext } from './types';

export interface Session {
    idToken?: string;
    accessToken?: string;

    // Session expirarion in seconds
    expiresAt?: number;

    // Logged in user (if available)
    user?: UserModel;

    // Logged in user votes
    votes?: VotesInContext;
}

export interface SessionContextType {
    session: Session;
    setSession: (session: Session) => void;
}

export const SessionContext = createContext<SessionContextType>({
    session: {},
    setSession: (_s: Session) => {
        return;
    },
});
