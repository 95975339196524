import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { ThemeProvider } from '@mui/material';

import { Processes, ProcessesContext } from '../../../contexts/processes';
import { Session, SessionContext } from '../../../contexts/session';
import { CookieNames, CookiesType } from '../../../utils/cookies/types';
import CookieConcent from '../CookieConcent/CookieConcent';
import InstallApp from '../InstallApp/InstallApp';
import {
    AnonymousUser,
    AnonymousUserContext,
} from '../../../contexts/anonUser';
import AppRouter from '../AppRouter/AppRouter';
import { Category } from '../../../models/Category';
import { CategoriesContext } from '../../../contexts/categories';
import { CategoryAPI } from '../../../api/CategoryAPI';
import { lightTheme } from '../themes/themes';

const App = (): JSX.Element => {
    const [cookies] = useCookies<CookieNames, CookiesType>([
        CookieNames.Session,
    ]);
    const [session, setSession] = useState<Session>(
        cookies[CookieNames.Session] ?? {},
    );
    const [processes, setProcesses] = useState<Processes>({});

    // Anonymous user gets initialized based on cookie consent
    const [anonUser, setAnonUser] = useState<AnonymousUser>(
        cookies[CookieNames.AnonymousUser] ?? {},
    );
    const [categories, setCategories] = useState<Category[] | null>(null);
    const [tout, setTout] = useState<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        const getCategories = async (): Promise<void> => {
            try {
                const categories = await CategoryAPI.getAll();
                setCategories(categories);
            } catch (e: unknown) {}
        };

        if (categories === null) {
            getCategories();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (session.expiresAt && !tout) {
            const timeout = session.expiresAt - Date.now();

            setTout(
                setTimeout(() => {
                    if (session.idToken) {
                        setSession({});
                    }
                }, timeout),
            );
        } else if (!session.expiresAt) {
            setTout(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            <ProcessesContext.Provider value={{ processes, setProcesses }}>
                <AnonymousUserContext.Provider
                    value={{ anonUser, setAnonUser }}
                >
                    <CategoriesContext.Provider
                        value={{ categories, setCategories }}
                    >
                        <ThemeProvider theme={lightTheme}>
                            <AppRouter>
                                <CookieConcent />
                                <InstallApp />
                            </AppRouter>
                        </ThemeProvider>
                    </CategoriesContext.Provider>
                </AnonymousUserContext.Provider>
            </ProcessesContext.Provider>
        </SessionContext.Provider>
    );
};

export default App;
