import { Grid, useMediaQuery } from '@mui/material';
import { breakpoint12 } from '../../../utils/layouts';
import CorporateLinks from '../../SideColumnsContent/CorporateLinks/CorporateLinks';
import './LeftColumn.scss';

const LeftColumn = (): JSX.Element => {
    const desktop = useMediaQuery(breakpoint12);

    return (
        <Grid id='left-column' item>
            {!desktop && <CorporateLinks />}
        </Grid>
    );
};

export default LeftColumn;
