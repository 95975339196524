import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { Category } from '../models/Category';
import Logger from '../utils/logger';

export class CategoryAPI {
    private static getCategoryAPIAxiosObj = (): AxiosInstance => {
        const headers: AxiosRequestHeaders = {
            'Content-Type': 'application/json',
        };

        return axios.create({
            baseURL: process.env.REACT_APP_CATEGORY_API_BASEURL,
            headers,
        });
    };

    public static getAll = async (): Promise<Category[]> => {
        const categories: Category[] = [];
        const api = CategoryAPI.getCategoryAPIAxiosObj();

        try {
            const response = await api.get('');

            if (response.status === 200 && response.data) {
                if (response.data.data && Array.isArray(response.data.data)) {
                    if (response.data.count) {
                        response.data.data.forEach((category: any) => {
                            if (category.id && category.name) {
                                const newCategory: Category = {
                                    id: category.id,
                                    name: category.name,
                                };

                                if (category.parentId) {
                                    newCategory.parentId = category.parentId;
                                }
                                categories.push(newCategory);
                            } else {
                                Logger.error(
                                    'Error calling CategoryAPI.getAll(). ' +
                                        'Response record format mismatch. ' +
                                        'response: ' +
                                        JSON.stringify(response) +
                                        ' record: ' +
                                        JSON.stringify(category),
                                );
                                throw new Error('Unexpected error');
                            }
                        });
                    }
                } else {
                    Logger.error(
                        'Error calling CategoryAPI.getAll(). Response format mismatch. ' +
                            'response: ' +
                            JSON.stringify(response),
                    );
                    throw new Error('Unexpected error');
                }
            } else {
                Logger.error(
                    'Error calling CategoryAPI.getAll(). response: ' +
                        JSON.stringify(response),
                );
                throw new Error('Unexpected error');
            }
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                Logger.error(
                    'Error calling CategoryAPI.getAll(). Axios.get() error. ' +
                        JSON.stringify(e),
                );
            }

            throw e;
        }

        return categories;
    };
}
