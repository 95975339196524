import { Button } from '@mui/material';

interface FormButtonProps {
    disabled?: boolean | undefined;
    type?: 'button' | 'submit' | 'reset';
}

const FormButton = (
    props: React.PropsWithChildren<FormButtonProps>,
): JSX.Element => {
    return (
        <Button variant='contained' {...props}>
            {props.children}
        </Button>
    );
};

export default FormButton;
