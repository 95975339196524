import { Button } from '@mui/material';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import PageLayout from '../../../components/Layout/PageLayout';
import './NotFound.scss';

const onClick = (navigate: NavigateFunction): void => {
    navigate('/');
};

const NotFound = (): JSX.Element => {
    const navigate = useNavigate();

    return (
        <PageLayout>
            <div className='not-found-container'>
                <h1>Page Not Found</h1>
                <p>
                    The page you tried to reach does not exist. If you believe
                    this is an error on our end, please contact us with details
                    and we will review it as soon as possible. Thanks!
                </p>
                <Button variant='contained' onClick={() => onClick(navigate)}>
                    Return to the home page
                </Button>
            </div>
        </PageLayout>
    );
};

export default NotFound;
