import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { SessionContext } from '../../../contexts/session';
import { CookieNames, CookiesType } from '../../../utils/cookies/types';

const SignOut = (): JSX.Element => {
    const sessionContext = useContext(SessionContext);
    const [, , removeCookie] = useCookies<CookieNames, CookiesType>([
        CookieNames.Session,
    ]);
    const navigate = useNavigate();

    useEffect(() => {
        sessionContext.setSession({});
        removeCookie(CookieNames.Session, {
            path: '/',
            domain: window.location.hostname,
        });
        navigate('/');
    }, [navigate, removeCookie, sessionContext]);

    return <></>;
};

export default SignOut;
