import { createContext } from 'react';
import { Category } from '../models/Category';

export interface CategoriesContextType {
    categories: Category[] | null;
    setCategories: (c: Category[] | null) => void;
}

export const CategoriesContext = createContext<CategoriesContextType>({
    categories: null,
    setCategories: (_c: Category[] | null) => {},
});
