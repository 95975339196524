import ReactDOM from 'react-dom';

const checkEnvVar = (envVar: string): boolean => {
    if (!process.env[envVar]) {
        console.error(`${envVar} not defined as environment variable`);
        ReactDOM.render(
            <>Environment variables not defined</>,
            document.getElementById('root'),
        );

        return false;
    }

    return true;
};

export const envVarOk = (): boolean => {
    return (
        checkEnvVar('REACT_APP_USER_API_BASEURL') &&
        checkEnvVar('REACT_APP_POLL_API_BASEURL') &&
        checkEnvVar('REACT_APP_IMAGE_API_BASEURL') &&
        checkEnvVar('REACT_APP_COGNITO_CLIENT_ID') &&
        checkEnvVar('REACT_APP_IMAGES_URL') &&
        checkEnvVar('REACT_APP_VOTE_API_BASEURL') &&
        checkEnvVar('REACT_APP_COGNITO_REGION')
    );
};
