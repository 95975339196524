import AWS from 'aws-sdk';
import { createContext } from 'react';

export interface Processes {
    signin?: AWS.CognitoIdentityServiceProvider.InitiateAuthResponse;
    redirect?: string;
    signinMsg?: string;
}

export interface ProcessesContextType {
    processes: Processes;
    setProcesses: (processes: Processes) => void;
}

export const ProcessesContext = createContext<ProcessesContextType>({
    processes: {},
    setProcesses: (_s: Processes) => {
        return;
    },
});
