import { CorporatePages } from './types';

class CorporatePageController {
    public static getFileContentAsArray = async (
        page: CorporatePages,
    ): Promise<string[]> => {
        let response: string[] = [];

        try {
            const response = await fetch(page);

            if (!response.ok || response.status !== 200) {
                throw new Error(`Error fetching ${page}`);
            }

            return (await response.text()).split('\n');
        } catch (e: unknown) {
            response = ['Error retrieving privacy policy text'];
        }

        return response;
    };
}

export default CorporatePageController;
