import { useContext, useEffect, useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import { isMobile } from 'react-device-detect';

import { SessionContext, SessionContextType } from '../../../contexts/session';
import {
    AnonymousUserContext,
    AnonymousUserContextType,
} from '../../../contexts/anonUser';

import './InstallApp.scss';

interface BeforeInstallPromptEvent extends Event {
    readonly userChoice: Promise<{
      outcome: 'accepted' | 'dismissed',
      platform: string,
    }>;
    prompt(): Promise<void>;
  }

const onClickAcceptHandler = async (
    setOpen: (o: boolean) => void,
    promptEvent: BeforeInstallPromptEvent | undefined,
    setPromptEvent: React.Dispatch<React.SetStateAction<BeforeInstallPromptEvent | undefined>>,
): Promise<void> => {
    setOpen(false);

    if(promptEvent) {
        // Show the install prompt.
        promptEvent.prompt();

        await promptEvent.userChoice;
        
        setPromptEvent(undefined);
    }
};

const InstallApp = (): JSX.Element => {
    const { session } = useContext<SessionContextType>(SessionContext);
    const [open, setOpen] = useState<boolean>(false);
    const [promptEvent, setPromptEvent] = useState<BeforeInstallPromptEvent>();
    const anonymousUserContext =
        useContext<AnonymousUserContextType>(AnonymousUserContext);

    useEffect(() => {
        const eventHandler = (event: Event) => {
            // Prevent the mini-infobar from appearing on mobile.
            event.preventDefault();

            event.target?.removeEventListener(event.type, eventHandler);
            
            setPromptEvent(event as BeforeInstallPromptEvent);
        };

        if (isMobile) {
            window.addEventListener('beforeinstallprompt', eventHandler);
        }
    }, []);

    useEffect(() => {
        setOpen(
            Boolean(
                isMobile &&
                promptEvent &&
                (session.user || anonymousUserContext.anonUser.acceptCookies)
            ),
        );
    }, [promptEvent, session, anonymousUserContext.anonUser.acceptCookies]);

    const action = (
        <>
            <Button
                variant='contained'
                size='small'
                onClick={() =>
                    onClickAcceptHandler(
                        setOpen,
                        promptEvent,
                        setPromptEvent,
                    )
                }
            >
                Install
            </Button>
            <Button
                variant='contained'
                size='small'
                onClick={() =>
                    setOpen(false)
                }
            >
                Later
            </Button>
        </>
    );

    const message = (
        <p>
            Install SeleOne App
        </p>
    );

    return (
        <Snackbar
            id='install-app'
            className='base-snackbar'
            open={open}
            message={message}
            action={action}
        />
    );
};

export default InstallApp;
