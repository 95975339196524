import React from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const queryParamNames = {
    referrer: 'ref',
};
