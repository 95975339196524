import axios, { AxiosError, AxiosInstance, AxiosRequestHeaders } from 'axios';
import jwt_decode from 'jwt-decode';

import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError,
} from './utils/data-management';
import { Session } from '../contexts/session';
import { apiError } from './types';
import {
    AnonymousUserModel,
    NewUser,
    UserModel,
    UserStats,
} from '../models/User';
import Logger from '../utils/logger';

interface DecodedToken {
    'custom:userId'?: string;
}

class UserAPI {
    private static getUserAPIAxiosObj = (session?: Session): AxiosInstance => {
        const headers: AxiosRequestHeaders = {
            'Content-Type': 'application/json',
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_USER_API_BASEURL;

        return axios.create({
            baseURL,
            headers,
        });
    };

    public static create = async (
        userData: NewUser,
    ): Promise<apiError | undefined> => {
        const userAPI = UserAPI.getUserAPIAxiosObj();

        try {
            await userAPI.post('', userData);
        } catch (err: any) {
            let error: apiError;
            const { status, data } = err.response;

            // Validation error
            if (status === 400 && data instanceof Array && data.length) {
                error = apiErrorsToConsolidatedErrorObject(data);
            } else {
                error = generateApiUnexpectedError('creating user');
            }

            return error;
        }
    };

    public static createAnonymous = async (
        referrer?: string,
    ): Promise<apiError | AnonymousUserModel> => {
        const userAPI = UserAPI.getUserAPIAxiosObj();

        try {
            const response = await userAPI.post(
                '/anonymous',
                referrer ? { referrer } : undefined,
            );

            if (response.status === 200 && response.data) {
                return { id: response.data.id };
            } else {
                throw new Error();
            }
        } catch (err: unknown) {
            let error: apiError;

            if (
                err &&
                typeof err === 'object' &&
                'response' in err &&
                (err as AxiosError).response?.status === 400 &&
                (err as AxiosError).response?.data instanceof Array &&
                (err as AxiosError).response?.data.length
            ) {
                error = apiErrorsToConsolidatedErrorObject(
                    (err as AxiosError).response?.data,
                );
            } else {
                error = generateApiUnexpectedError('creating user');
            }

            return error;
        }
    };

    private static get = async (
        session: Session,
        userId: string,
    ): Promise<UserModel> => {
        const userAPI = UserAPI.getUserAPIAxiosObj(session);

        const { status, data } = await userAPI.get<UserModel>(`/${userId}`);

        if (status === 200 && data && data.id && data.email && data.username) {
            return data;
        } else {
            throw new Error('Error retrieving logged in user');
        }
    };

    public static getSelf = async (session: Session): Promise<UserModel> => {
        if (session.idToken) {
            const decodedToken = jwt_decode<DecodedToken>(session.idToken);

            if (decodedToken && decodedToken['custom:userId']) {
                return UserAPI.get(session, decodedToken['custom:userId']);
            }
        }

        throw new Error('Session token missing or invalid');
    };

    public static getSelfStats = async (
        session: Session,
    ): Promise<UserStats> => {
        if (session.idToken) {
            const decodedToken = jwt_decode<DecodedToken>(session.idToken);

            if (decodedToken && decodedToken['custom:userId']) {
                const userId = decodedToken['custom:userId'];
                const userAPI = UserAPI.getUserAPIAxiosObj(session);

                const { status, data } = await userAPI.get<UserStats>(
                    `/${userId}/stats`,
                );

                if (status === 200 && data && data.userId) {
                    return data;
                } else {
                    throw new Error('Error retrieving logged in user stats');
                }
            }
        }

        throw new Error('Session token missing or invalid');
    };

    public static forgotPassword = async (
        email: string,
    ): Promise<apiError | string> => {
        const userAPI = UserAPI.getUserAPIAxiosObj();

        try {
            const response = await userAPI.post('/forgotpassword', {
                email,
            });

            if (
                response.status === 200 &&
                response.data !== undefined &&
                response.data.code !== undefined
            ) {
                return response.data.code;
            } else {
                Logger.error(
                    'Unexpected error resetting password' +
                        JSON.stringify(response),
                );
                return generateApiUnexpectedError('creating user');
            }
        } catch (err: any) {
            let error: apiError;
            const { status, data } = err.response;

            // Validation error
            if (status === 400 && data instanceof Array && data.length) {
                error = apiErrorsToConsolidatedErrorObject(data);
            } else {
                error = generateApiUnexpectedError('creating user');
            }

            return error;
        }
    };
}

export const passwordRecoveryResponseCodeRecoveryCodeSent = 'recoveryCodeSent';
export const passwordRecoveryResponseCodeTempPasswordResent =
    'temporaryPasswordResent';

export default UserAPI;
