import { useEffect, useState } from 'react';

import CorporatePageContainer from '../../../components/corporate/CorporatePageContainer';
import CorporatePageController from '../CorporatePageController';
import { CorporatePages } from '../types';

const TermsAndConditions = (): JSX.Element => {
    const [content, setContent] = useState<string[]>([]);
    useEffect(() => {
        const getContent = async () => {
            const content = await CorporatePageController.getFileContentAsArray(
                CorporatePages.TermsAndConditions,
            );

            setContent(content);
        };

        getContent();
    }, []);

    return <CorporatePageContainer title='Terms Of Use' content={content} />;
};

export default TermsAndConditions;
