import { CircularProgress } from '@mui/material';

import './Overlay.scss';

interface OverlayProps {
    processing?: boolean;
}

const Overlay = (props: OverlayProps): JSX.Element => {
    return (
        <>
            {props.processing && (
                <div className='overlay'>
                    <CircularProgress color='primary' />
                </div>
            )}
        </>
    );
};

export default Overlay;
