import { LoggerAPI } from '../api/LoggerAPI';

type ErrorSeverity = 'error' | 'debug' | 'warning' | 'info';

const Logger = {
    error: async (msg: string): Promise<void> => Logger.log('error', msg),
    debug: async (msg: string): Promise<void> => Logger.log('debug', msg),
    warning: async (msg: string): Promise<void> => Logger.log('warning', msg),
    info: async (msg: string): Promise<void> => Logger.log('info', msg),

    /**
     * This should be refactored in the future to incorporate logic and a call
     * to an external logger.
     *
     * @param _severity Severity of the log entry
     * @param _msg Message to log
     * @returns Promise to allow asynchronous logging (avoiding UX interferance)
     */
    log: async (severity: ErrorSeverity, msg: string): Promise<void> => {
        return new Promise((resolve, reject): void => {
            if (
                !process.env.NODE_ENV ||
                process.env.NODE_ENV === 'development'
            ) {
                console.error(`${severity}: ${msg}`);
                resolve();
            } else {
                resolve(
                    LoggerAPI.submitEntry({
                        type: severity === 'info' ? 'log' : severity,
                        message: msg,
                    }),
                );
            }
        });
    },
};

export default Logger;
