import { Link } from 'react-router-dom';
import { Paper, Slide, useMediaQuery, useScrollTrigger } from '@mui/material';

import Navigation from '../Navigation/Navigation';
import { breakpoint7, breakpoint9 } from '../../../utils/layouts';
import { UseScrollTriggerOptions } from '@mui/material/useScrollTrigger/useScrollTrigger';

import './Header.scss';

interface HeaderProps {
    scrollTrigger?: HTMLElement | null;
}

const Header = (props: HeaderProps): JSX.Element => {
    const tabletPlus = useMediaQuery(breakpoint7);
    const tabletLandscapePlus = useMediaQuery(breakpoint9);

    const trigger = useScrollTrigger({
        target: tabletLandscapePlus ? props.scrollTrigger : undefined,
        threshold: 50,
    } as UseScrollTriggerOptions);

    return (
        <Slide
            id='header'
            appear={tabletPlus}
            direction='down'
            in={tabletPlus || !trigger}
        >
            <header>
                <div id='header-container'>
                    <div id='logo-header'>
                        <Link to='/'>
                            <img
                                id='logo'
                                src='/img/logo_120.png'
                                alt='SeleOne logo'
                            />
                        </Link>
                    </div>
                    {tabletPlus && (
                        <Paper id='navigation-options' elevation={0}>
                            <Navigation />
                        </Paper>
                    )}
                </div>
            </header>
        </Slide>
    );
};

export default Header;
