import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

interface LoggerAPIData {
    type?: 'log' | 'error' | 'warning' | 'debug';
    message: string;
}

export class LoggerAPI {
    private static getLoggerAPIAxiosObj = (): AxiosInstance => {
        const headers: AxiosRequestHeaders = {
            'Content-Type': 'application/json',
        };

        return axios.create({
            baseURL: process.env.REACT_APP_LOGGER_API_BASEURL,
            headers,
        });
    };

    public static submitEntry = (data: LoggerAPIData): Promise<void> => {
        return LoggerAPI.getLoggerAPIAxiosObj().post('', data);
    };
}
