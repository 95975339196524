import { createContext } from 'react';
import { AnonymousUserModel } from '../models/User';

import { VotesInContext } from './types';

export interface AnonymousUser {
    //
    acceptCookies?: boolean;

    // Anonymous user (if available)
    user?: AnonymousUserModel;

    // Logged in user votes
    votes?: VotesInContext;

    // Referrer user ID if available
    //http://localhost:3000/poll/c025bd4b-8aee-4ed0-bcce-6a2adffc8489?ref=b41ccbe5-b705-409d-84c6-5f0934cce7bc
    referrer?: string;
}

export interface AnonymousUserContextType {
    anonUser: AnonymousUser;
    setAnonUser: (a: AnonymousUser) => void;
}

export const AnonymousUserContext = createContext<AnonymousUserContextType>({
    anonUser: {},
    setAnonUser: (_a: AnonymousUser) => {
        return;
    },
});
