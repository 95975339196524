import { Divider, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { breakpoint9 } from '../../../../utils/layouts';
import { RoutePaths } from '../../../core/AppRouter/AppRouter';

import './AccountMenu.scss';

interface AccountMenuProps {
    anchorEl: HTMLElement | null;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountMenu = (props: AccountMenuProps): JSX.Element => {
    const navigate = useNavigate();
    const tabletLandscapePlus = useMediaQuery(breakpoint9);

    return (
        <Menu
            id='account-menu'
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={() => props.setOpen(false)}
            MenuListProps={{
                dense: true,
            }}
        >
            <MenuItem onClick={() => navigate(`/${RoutePaths.MyPolls}`)}>
                My Polls
            </MenuItem>
            {!tabletLandscapePlus && (
                <MenuItem onClick={() => navigate('/corporate/contact-us')}>
                    Contact Us
                </MenuItem>
            )}
            {!tabletLandscapePlus && (
                <MenuItem onClick={() => navigate('/corporate/privacy-policy')}>
                    Privacy Policy
                </MenuItem>
            )}
            {!tabletLandscapePlus && (
                <MenuItem onClick={() => navigate('/corporate/terms-of-use')}>
                    Terms Of Use
                </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={() => navigate('/auth/sign-out')}>
                Sign Out
            </MenuItem>
        </Menu>
    );
};

export default AccountMenu;
