import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    BottomNavigation,
    BottomNavigationAction,
    Button,
    DialogActions,
    DialogContent,
} from '@mui/material';
import { AddTask, FormatListBulleted, Home, Person } from '@mui/icons-material';

import { SessionContext, SessionContextType } from '../../../contexts/session';
import BaseDialog from '../BaseDialog/BaseDialog';
import AccountMenu from './AccountMenu/AccountMenu';

import './Navigation.scss';

enum navOptions {
    'Home' = 'home',
    'Create' = 'create',
    'Account' = 'account',
    'Categories' = 'categories',
}

const Navigation = (): JSX.Element => {
    const navigate = useNavigate();
    const [option, setOption] = useState<string>('');
    const { session } = useContext<SessionContextType>(SessionContext);
    const location = useLocation();
    const [disabledAlertOpen, setDisabledAlertOpen] = useState<boolean>(false);
    const accountButtonRef = useRef(null);
    const [accountOpen, setAccountOpen] = useState<boolean>(false);

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setOption(navOptions.Home);
                break;
            case '/auth/sign-in':
                setOption(navOptions.Account);
                break;
            case '/polls/new':
                setOption(navOptions.Create);
                break;
        }
    }, [location]);

    const accountLabel = session.user ? 'Account' : 'Sign In';

    const onAccountClickHandler = (
        event: React.MouseEvent<HTMLElement>,
    ): void => {
        if (!session.user) {
            navigate('/auth/sign-in');
        } else {
            setAccountOpen(true);
        }
    };

    return (
        <>
            <BottomNavigation id='navigation' showLabels value={option}>
                <BottomNavigationAction
                    label='Home'
                    icon={<Home />}
                    component={Link}
                    to='/'
                    value={navOptions.Home}
                />
                <BottomNavigationAction
                    label='Categories'
                    color='disabled'
                    icon={<FormatListBulleted />}
                    value={navOptions.Categories}
                    onClick={() => setDisabledAlertOpen(true)}
                />
                <BottomNavigationAction
                    label='Create'
                    color={!session.user ? 'disabled' : 'inherit'}
                    icon={<AddTask />}
                    component={Link}
                    to='/polls/new'
                    value={navOptions.Create}
                />
                <BottomNavigationAction
                    ref={accountButtonRef}
                    label={accountLabel}
                    icon={<Person />}
                    value={navOptions.Account}
                    onClick={onAccountClickHandler}
                />
            </BottomNavigation>
            <AccountMenu
                anchorEl={accountButtonRef.current}
                open={accountOpen}
                setOpen={setAccountOpen}
            />
            <BaseDialog
                open={disabledAlertOpen}
                onClose={() => setDisabledAlertOpen(false)}
            >
                <DialogContent>
                    Categories will be avilable in the next version. Stay tuned!
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setDisabledAlertOpen(false)}
                    >
                        Close
                    </Button>
                </DialogActions>
            </BaseDialog>
        </>
    );
};

export default Navigation;
