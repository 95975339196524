import { createTheme } from '@mui/material/styles';
import { primaryColor, secondaryColor } from './colors';

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: secondaryColor,
        },
    },
    typography: {
        fontFamily: ['Open Sans', 'Montserrat', 'Arial'].join(','),
    },
});
