import { useContext, useEffect, useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import {
    AnonymousUser,
    AnonymousUserContext,
    AnonymousUserContextType,
} from '../../../contexts/anonUser';
import { CookieNames, CookiesType } from '../../../utils/cookies/types';
import UserAPI from '../../../api/UserAPI';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import { queryParamNames, useQuery } from '../../../utils/location';

const CookieConcent = (): JSX.Element => {
    const { session } = useContext<SessionContextType>(SessionContext);
    const [open, setOpen] = useState<boolean>(false);
    const { anonUser, setAnonUser } =
        useContext<AnonymousUserContextType>(AnonymousUserContext);
    const [, setCookie] = useCookies<CookieNames, CookiesType>([
        CookieNames.Session,
    ]);
    const query = useQuery();

    useEffect(() => {
        // Detect and populate referrer user ID
        if (
            !anonUser.referrer &&
            ['', 'null'].find(
                (element) =>
                    element === String(query.get(queryParamNames.referrer)),
            ) === undefined
        ) {
            const newAnonUser: AnonymousUser = {
                ...anonUser,
                referrer: String(query.get(queryParamNames.referrer)),
            };

            setAnonUser(newAnonUser);

            // Save response in cookie (no expiration) to avoid user being prompted
            // again in the future
            setCookie(CookieNames.AnonymousUser, newAnonUser, {
                path: '/',
                // Expires in 100 years (implementation of "never expires")
                expires: new Date(Date.now() + 100 * 365 * 24 * 60 * 60000),
                domain: window.location.hostname,
                secure: true,
            });
        }

        setOpen(Boolean(!session.user && anonUser.acceptCookies === undefined));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, anonUser, query]);

    const onClickAcceptHandler = async (): Promise<void> => {
        setOpen(false);

        // Register new anonymous user
        const result = await UserAPI.createAnonymous(anonUser.referrer);

        if (result && result.id) {
            // Save response in session (context)
            const newAnonUser: AnonymousUser = {
                ...anonUser,
                acceptCookies: true,
                user: {
                    id: result.id,
                },
            };
            setAnonUser(newAnonUser);

            // Save response in cookie (no expiration) to avoid user being prompted
            // again in the future
            setCookie(CookieNames.AnonymousUser, newAnonUser, {
                path: '/',
                // Expires in 100 years (implementation of "never expires")
                expires: new Date(Date.now() + 100 * 365 * 24 * 60 * 60000),
                domain: window.location.hostname,
                secure: true,
            });
        }
    };

    const onClickDoNotAcceptHandler = (): void => {
        // Save response in session (context)
        const newAnonUser: AnonymousUser = {
            acceptCookies: false,
        };

        setAnonUser(newAnonUser);
        setOpen(false);
    };

    const action = (
        <>
            <Button
                variant='contained'
                size='small'
                onClick={onClickAcceptHandler}
            >
                I Accept
            </Button>
            <Button
                variant='contained'
                size='small'
                onClick={onClickDoNotAcceptHandler}
            >
                I do not accept
            </Button>
        </>
    );

    const message = (
        <p>
            We use cookies to improve the user experience and allow some
            anonymous functionality. Please read our{' '}
            <Link to='/corporate/privacy-policy'>Privacy Policy</Link> and{' '}
            <Link to='/corporate/terms-of-service'>Terms of Service</Link> for
            more details on how we use cookies and protect your privacy.
        </p>
    );

    return (
        <Snackbar
            id='cookie-consent'
            className='base-snackbar'
            open={open}
            message={message}
            action={action}
            sx={{ bottom: '0.5rem' }}
        />
    );
};

export default CookieConcent;
