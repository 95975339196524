import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import { envVarOk } from './utils/sanity';
import App from './components/core/App/App';

import './index.scss';

if (envVarOk()) {
    if (window.location.hostname === 'www.seleone.com') {
        window.location.replace(
            `https://seleone.com${window.location.pathname}`,
        );
    } else {
        ReactDOM.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>,
            document.getElementById('root'),
        );

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://cra.link/PWA
        serviceWorkerRegistration.register();

        // If you want to start measuring performance in your app, pass a function
        // to log results (for example: reportWebVitals(console.log))
        // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
        // reportWebVitals();
    }
}
