import { TextField } from '@mui/material';
import React from 'react';
import { Field } from 'react-final-form';

interface FormTextFieldProps {
    name: string;
    label?: string | undefined;
    autoComplete?: string | undefined;
    fullWidth?: boolean | undefined;
    required?: boolean | undefined;
    multiline?: boolean | undefined;
    minRows?: number | undefined;
    maxRows?: number | undefined;
    select?: boolean | undefined;
    id?: string | undefined;
    autoFocus?: boolean | undefined;
    value?: string | number | boolean | undefined;
    initialValue?: string | number | boolean | undefined;
    type?: React.InputHTMLAttributes<unknown>['type'] | undefined;
    onChange?: (...p: any) => void;
    onFocus?:
        | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
        | undefined;
}

const FormTextField = (
    props: React.PropsWithChildren<FormTextFieldProps>,
): JSX.Element => {
    const textFieldProps = { ...props };
    delete textFieldProps.initialValue;

    return (
        <Field name={props.name} initialValue={props.initialValue}>
            {({ input, meta }) => (
                <TextField
                    autoComplete='off'
                    error={(meta.error || meta.submitError) && meta.touched}
                    helperText={
                        (meta.touched && (meta.error || meta.submitError)) ||
                        ' '
                    }
                    variant='outlined'
                    size='small'
                    {...input}
                    {...textFieldProps}
                />
            )}
        </Field>
    );
};

export default FormTextField;
