import { Container } from '@mui/material';
import { SubmissionErrors } from 'final-form';
import { useState } from 'react';
import { Form } from 'react-final-form';
import * as yup from 'yup';

import { ContactUsAPI } from '../../../api/ContactUsAPI';
import Alerts, { AlertObj } from '../../../components/Alerts/Alerts';
import FormButton from '../../../components/forms/FormButton/FormButton';
import FormRowContainer from '../../../components/forms/FormRowContainer/FormRowContainer';
import FormTextField from '../../../components/forms/FormTextField/FormTextField';
import FormTitle from '../../../components/forms/FormTitle/FormTitle';
import Overlay from '../../../components/Layout/Overlay/Overlay';
import PageLayout from '../../../components/Layout/PageLayout';
import CardContainer from '../../../components/SideColumnsContent/CardContainer/CardContainer';
import { validateFormValues } from '../../../utils/forms/validation';

import './ContactUs.scss';

const validate = validateFormValues(
    yup.object({
        name: yup.string().min(3).required(),
        message: yup.string().min(10).max(200).required(),
        email: yup.string().max(200).email().optional(),
        phone: yup
            .string()
            .min(10)
            .max(30)
            .when('email', {
                is: (email: unknown) =>
                    typeof email === 'string' && Boolean(email),
                then: (schema) => schema.optional(),
                otherwise: (schema) =>
                    schema.required(
                        'You need to provide either email or a phone number',
                    ),
            }),
    }),
);

const ContactUs = (): JSX.Element => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [alerts, setAlerts] = useState<AlertObj[]>([]);

    const onSubmitHandler = async (
        values: any,
    ): Promise<SubmissionErrors | void> => {
        setProcessing(true);

        const result = await ContactUsAPI.submit(values);

        if (result) {
            setAlerts([
                {
                    text: 'Contact Us form submitted successfully',
                    severity: 'info',
                },
            ]);
        } else {
            setAlerts([
                {
                    text: 'There was an error submitting the form. Please try again later.',
                    severity: 'error',
                },
            ]);
        }

        setProcessing(false);
    };

    return (
        <PageLayout>
            <Overlay processing={processing} />
            <Container id='contact-us-form-container'>
                <Alerts alerts={alerts} setAlerts={setAlerts} />
                <CardContainer className='form-root'>
                    <Form
                        onSubmit={onSubmitHandler}
                        validate={validate}
                        render={({
                            handleSubmit,
                            form,
                            submitting,
                            pristine,
                            invalid,
                        }) => (
                            <form
                                {...form}
                                id='contact-us-form'
                                onSubmit={handleSubmit}
                            >
                                <FormTitle text='Contact Us' />
                                <FormRowContainer>
                                    <FormTextField
                                        name='name'
                                        label='Full Name'
                                        fullWidth
                                        required
                                        autoFocus
                                        autoComplete='name'
                                    />
                                </FormRowContainer>
                                <FormRowContainer>
                                    <FormTextField
                                        name='message'
                                        label='Message'
                                        fullWidth
                                        required
                                        multiline
                                        minRows={4}
                                        maxRows={8}
                                    />
                                </FormRowContainer>
                                <FormRowContainer>
                                    <FormTextField
                                        name='email'
                                        label='Email'
                                        fullWidth
                                        autoComplete='email'
                                    />
                                </FormRowContainer>
                                <FormRowContainer>
                                    <FormTextField
                                        name='phone'
                                        label='Phone'
                                        fullWidth
                                        autoComplete='phone'
                                    />
                                </FormRowContainer>
                                <FormRowContainer>
                                    <FormButton
                                        disabled={
                                            submitting || pristine || invalid
                                        }
                                        type='submit'
                                    >
                                        Submit
                                    </FormButton>
                                </FormRowContainer>
                            </form>
                        )}
                    />
                </CardContainer>
            </Container>
        </PageLayout>
    );
};

export default ContactUs;
