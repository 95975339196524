// Mobile landscape
export const breakpoint4 = '(min-width:480px)';

// Tablet portrait
export const breakpoint7 = '(min-width:768px)';

// Tablet landscape
export const breakpoint9 = '(min-width:992px)';

// Desktop
export const breakpoint12 = '(min-width:1200px)';
