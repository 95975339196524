import * as yup from 'yup';
import { setIn } from 'final-form';

export const validateFormValues =
    (schema: yup.AnySchema) =>
    async (values: any): Promise<{} | undefined> => {
        try {
            await schema.validate(values, { abortEarly: false });
        } catch (err: unknown) {
            if (err instanceof yup.ValidationError) {
                const errors = err.inner.reduce(
                    (formError: {}, innerError: yup.ValidationError) => {
                        return setIn(
                            formError,
                            String(innerError.path),
                            innerError.message,
                        );
                    },
                    {},
                );

                return errors;
            }
        }
    };

export const validImage = (file: File): boolean => {
    if (['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
        return true;
    }

    return false;
};
