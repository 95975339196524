import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import SignOut from '../../../pages/auth/SignOut/SignOut';
import ContactUs from '../../../pages/corporate/ContactUs/ContactUs';
import NotFound from '../../../pages/corporate/NotFound/NotFound';
import PrivacyPolicy from '../../../pages/corporate/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from '../../../pages/corporate/TermsOfUse/TermsOfUse';

export enum RoutePaths {
    NewPoll = 'polls/new',
    MyPolls = 'polls/mypolls',
    SignIn = 'auth/sign-in',
    Poll = 'poll/:pollId',
    ContactUs = 'corporate/contact-us',
}

const Home = lazy(() => import('../../../pages/Home/Home'));
const MyPolls = lazy(() => import('../../../pages/Polls/MyPolls/MyPolls'));
const NewPoll = lazy(() => import('../../../pages/Polls/NewPoll/NewPoll'));
const JoinNow = lazy(() => import('../../../pages/JoinNow/JoinNow'));
const SignIn = lazy(() => import('../../../pages/auth/SignIn/SignIn'));
const Challenge = lazy(() => import('../../../pages/auth/Challenge/Challenge'));
const PollPage = lazy(() => import('../../../pages/PollPage/PollPage'));
const ForgotPassword = lazy(
    () => import('../../../pages/auth/ForgotPassword/ForgotPassword'),
);

const AppRouter = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path={RoutePaths.SignIn} element={<SignIn />} />
                    <Route path='auth/join-now' element={<JoinNow />} />
                    <Route
                        path='auth/forgot-password'
                        element={<ForgotPassword />}
                    />
                    <Route path='auth/challenge' element={<Challenge />} />
                    <Route path='auth/sign-out' element={<SignOut />} />
                    <Route path={RoutePaths.NewPoll} element={<NewPoll />} />
                    <Route path={RoutePaths.MyPolls} element={<MyPolls />} />
                    <Route path={RoutePaths.Poll} element={<PollPage />} />
                    <Route
                        path={RoutePaths.ContactUs}
                        element={<ContactUs />}
                    />
                    <Route
                        path='corporate/privacy-policy'
                        element={<PrivacyPolicy />}
                    />
                    <Route
                        path='corporate/terms-of-use'
                        element={<TermsOfUse />}
                    />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
            {props.children}
        </BrowserRouter>
    );
};

export default AppRouter;
