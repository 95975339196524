// Basic color names
// $white: #ffffff;
// $extra-light-gray: #f0f0f0;
// $light-gray: #ecebeb;
// $light-gray-focus: #e0e0e0;
// $light-gray-plus: #bfbfbf;
// $gray: #9e9e9e;
// $dark-gray: #414141;
// $black: #090909;

export const blueColor = '#0455c0';
export const darkBlueColor = '#093a87';
// $red: #e23019;
export const orangeColor = '#fd900f';
// $dark-orange: #e98006;
// $extra-light-orange: #fff3e6;

// Generic color names
export const primaryColor = blueColor;
export const darkPrimaryColor = darkBlueColor;
export const secondaryColor = orangeColor;
// $dark-secondary: $dark-orange;
// $error: $red;

// // Specific color names
// $title-grey: $dark-gray;

// // Below all deprecated
// $light-pink: #ebdae4;
// $primary-hover: #6eb6b8;
// $dark-gray-old: #4f4f4f;

// $form-bg: $white;
// $form-button-bg: $primary;
// $form-button-bg-hover: $primary-hover;
// $input-bg: $light-gray;
// $input-bg-focus: $light-gray-focus;
// $input: $dark-gray;
// $input-placeholder: $gray;
// $link: $blue;
// $center-bg: $extra-light-gray;
