import { FORM_ERROR } from 'final-form';

import { apiError } from '../types';

export const apiErrorsToConsolidatedErrorObject = (errors: {}[]): apiError => {
    const rffErrors: apiError = {};

    errors.forEach((error: any) => {
        if (error && error.message && error.field) {
            rffErrors[error.field] = error.message;
        }
    });

    return rffErrors;
};

/**
 * This function is an generic API unexpected error generator that will take
 * a failed action text and used to compose a standard message.
 *
 * Example failedAction to provide: 'cating vote' or 'creating poll'
 *
 * @param failedAction failed action text describing when the error occurred
 * @returns apiError standard generic form error
 */
export const generateApiUnexpectedError = (failedAction: string): apiError => {
    return {
        [FORM_ERROR]: `Unexpected error ${failedAction}`,
    };
};
