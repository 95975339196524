import { Dialog } from '@mui/material';
import React from 'react';

interface BaseDialogProps {
    open: boolean;
    onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
}

const BaseDialog = (
    props: React.PropsWithChildren<BaseDialogProps>,
): JSX.Element => {
    return (
        <Dialog
            className='base-dialog'
            open={props.open}
            onClose={props.onClose}
        >
            {props.children}
        </Dialog>
    );
};

export default BaseDialog;
