import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import Logger from '../utils/logger';

export interface ContactUsData {
    name: string;
    message: string;
    email?: string;
    phone?: string;
}

export class ContactUsAPI {
    private static getContactUsAPIAxiosObj = (): AxiosInstance => {
        const headers: AxiosRequestHeaders = {
            'Content-Type': 'application/json',
        };

        return axios.create({
            baseURL: process.env.REACT_APP_CONTACT_US_API_BASEURL,
            headers,
        });
    };

    public static submit = async (data: ContactUsData): Promise<boolean> => {
        const contactUsAPI = ContactUsAPI.getContactUsAPIAxiosObj();
        let success = true;

        try {
            const response = await contactUsAPI.post('', data);

            if (response.status !== 200) {
                Logger.error(
                    'ContactUsAPI.submit() error. ' + JSON.stringify(response),
                );
                success = false;
            }
        } catch (error: unknown) {
            if (typeof error === 'object') {
                Logger.error(
                    'ContactUsAPI.submit() axios.post() error. ' +
                        JSON.stringify(error),
                );
            }

            success = false;
        }

        return success;
    };
}
