import { Grid } from '@mui/material';
import CorporateLinks from '../../SideColumnsContent/CorporateLinks/CorporateLinks';
import './RightColumn.scss';

const RightColumn = (): JSX.Element => {
    return (
        <Grid id='right-column' item>
            <CorporateLinks />
        </Grid>
    );
};

export default RightColumn;
