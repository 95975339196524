import { Link } from 'react-router-dom';

import CardContainer from '../CardContainer/CardContainer';

import './CorporateLinks.scss';

const CorporateLinks = (): JSX.Element => {
    return (
        <CardContainer title='About'>
            <ul className='corporate-links'>
                <li>
                    <Link to='/corporate/contact-us'>Contact Us</Link>
                </li>
                <li>
                    <Link to='/corporate/privacy-policy'>Privacy Policy</Link>
                </li>
                <li>
                    <Link to='/corporate/terms-of-use'>Terms Of Use</Link>
                </li>
                <li>
                    <span className='copyright'>SeleOne &copy; 2022</span>
                </li>
            </ul>
        </CardContainer>
    );
};

export default CorporateLinks;
