import PageLayout from '../Layout/PageLayout';
import CardContainer from '../SideColumnsContent/CardContainer/CardContainer';

import './CorporatePageContainer.scss';

interface CorporatePageContainerProps {
    title: string;
    content: string[];
}

const CorporatePageContainer = (
    props: CorporatePageContainerProps,
): JSX.Element => {
    return (
        <PageLayout>
            <CardContainer className='corporate-page'>
                <h1>{props.title}</h1>
                {props.content.map((line, idx) => (
                    <p key={idx}>{line}</p>
                ))}
            </CardContainer>
        </PageLayout>
    );
};

export default CorporatePageContainer;
