import { useEffect, useState } from 'react';

import CorporatePageContainer from '../../../components/corporate/CorporatePageContainer';
import CorporatePageController from '../CorporatePageController';
import { CorporatePages } from '../types';

const PrivacyPolicy = (): JSX.Element => {
    const [content, setContent] = useState<string[]>([]);
    useEffect(() => {
        const getContent = async () => {
            const content = await CorporatePageController.getFileContentAsArray(
                CorporatePages.PrivacyPolicy,
            );

            setContent(content);
        };

        getContent();
    }, []);

    return <CorporatePageContainer title='Privacy Policy' content={content} />;
};

export default PrivacyPolicy;
